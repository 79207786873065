<template>
  <div class="app-container">
    <el-card class="box-card">
      基本资料
      <el-table
        :data="tableData"
        stripe
        :show-header="false"
        border
        :span-method="arraySpanMethod"
        style="width: 100%">
        <el-table-column
          prop="column1"
          label="日期"
          width="180">
          <template slot-scope="scope">
            <el-button  type="text">{{scope.row.column1}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="column2"
          label="姓名"
          width="380">
        </el-table-column>
        <el-table-column
          prop="column3"
           width="180"
          label="地址">
          <template slot-scope="scope">
            <el-button  type="text">{{scope.row.column3}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="column4"
          width="380"
          label="地址">
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
export default {
  props: ['org_ID'],
  name: 'basicInfo',
  created () {
    this.getTableDataUser()
  },
  methods: {
    async getTableDataUser () {
      const { data: res } = await this.$http.get('query/org/by/id/detail', {
        params: this.form
      })
      this.tableData = res
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        if (columnIndex === 1) {
          return [1, 3]
        }
      }
    }
  },
  data () {
    return {
      form: {
        org_ID: this.org_ID
      },
      tableData: []
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.contextmenu {
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
    margin: 0;
    padding: 0px 22px;

}
.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.contextmenu li button{
   color: #2c3e50;
}
</style>
