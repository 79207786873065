<template>
  <div class="app-container">
    <el-row>
      <el-col :span="18">
        <div style="height:100px">
          <el-row>
            <el-card header="回访记录">
              <div style="height:580px">
                <el-table
                  :data="tableDataReview"  highlight-current-row
                  ref="singleTable"
                  border stripe
                  @row-click="reviewRowClick"
                  :row-style="{height: '0'}"
                  :cell-style="{padding: '11px'}"
                  height="540px"
                  style="width: 100%">
                  <el-table-column
                    fixed
                    prop="user_NAME"
                    label="账号"
                    sortable
                    width="120"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-link :underline="false" type="primary">{{scope.row.user_NAME}}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column
                    sortable
                    prop="real_NAME"
                    label="姓名"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="reviewer_NAME"
                    label="记录人"
                    sortable
                    width="90"
                    show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column
                    prop="CREATE_DATETIME"
                    label="记录时间"
                    sortable
                    :show-overflow-tooltip="true"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="remark"
                    label="备注说明"
                    width="500" :show-overflow-tooltip="true">
                  </el-table-column>
                </el-table>
                <el-pagination
                  @size-change="handleSizeChangeReview"
                  @current-change="handleCurrentChangeReview"
                  :current-page="queryInfoReview.pagenum"
                  :page-sizes="[2, 5, 10, 15]"
                  :page-size="queryInfoReview.pagesize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totleReview"
                ></el-pagination>
              </div>
            </el-card>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-card header="客户建议">
                <div style="height:110px">
                  {{proposal}}
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card header="备注">
                <div style="height:110px">
                  {{remark}}
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-light">
          <el-card header="账户筛选">
            <div style="height:764px">
              <el-table
                :data="tableDataUser"  highlight-current-row
                @row-click="userRowClick"
                ref="singleTableUser"
                border stripe
                @row-contextmenu="rightClick"
                :row-style="{height: '0'}"
                :cell-style="{padding: '3px'}"
                height="690px"
                style="width: 100%">
                <el-table-column
                  type="index"
                  width="50">
                </el-table-column>
                <el-table-column
                  prop="user_NAME"
                  label="账号"
                  sortable
                  width="130"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button  type="text">{{scope.row.user_NAME}}</el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  sortable
                  prop="real_NAME"
                  label="姓名"
                  width="90">
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeUser"
                @current-change="handleCurrentChangeUser"
                :current-page="queryInfoUser.pagenum"
                :page-sizes="[50]"
                :page-size="queryInfoUser.pagesize"
                layout="total, prev, next, jumper"
                :total="totleUser">
              </el-pagination>
              <el-dialog append-to-body  title="客户回访" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
                <el-form ref="form" class="demo-ruleForm" :model="form">
                  <el-form-item label="客户建议:">
                    <el-input type="textarea" :rows="4" v-model="form.proposal"></el-input>
                  </el-form-item>
                  <el-form-item label="备注说明:">
                    <el-input type="textarea" :rows="4" v-model="form.remark"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="text" @click="resetForm()" >取消</el-button>
                  <el-button type="primary" @click="submitFrom('form')">确认</el-button>
                </div>
              </el-dialog>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'Review',
  created () {
    this.getTableDataUser()
  },
  methods: {
    async getTableDataUser () {
      const { data: res } = await this.$http.get('query/platform/user/byorgid', {
        params: this.queryInfoUser
      })
      this.tableDataUser = res.list
      this.totleUser = res.total
    },
    async getTableDataReview () {
      const { data: res } = await this.$http.get('query/platform/review/byuserid', {
        params: this.queryInfoReview
      })
      this.tableDataReview = res.list
      this.totleReview = res.total
    },
    handleNodeClick (data) {
      // console.log(data)
      this.componentsId = data.id
    },
    handleCurrentChangeUser (newSize) {
      this.queryInfoUser.pagenum = newSize
      this.getTableDataUser()
    },
    handleSizeChangeUser (newSize) {
      this.queryInfoUser.pagesize = newSize
      this.getTableDataUser()
    },
    handleCurrentChangeReview (newSize) {
      this.queryInfoReview.pagenum = newSize
      this.getTableDataReview()
    },
    handleSizeChangeReview (newSize) {
      this.queryInfoReview.pagesize = newSize
      this.getTableDataReview()
    },
    async userRowClick (row, column, event) {
      this.queryInfoReview.query = row.user_ID
      this.proposal = ''
      this.remark = ''
      this.getTableDataReview()
    },
    reviewRowClick (row, column, event) {
      this.proposal = row.proposal
      this.remark = row.remark
    },
    async rightClick (row, column, event) {
      event.preventDefault()// 防止默认菜单弹出
      this.$refs.singleTableUser.setCurrentRow(row)// 设置选中
      this.addDialogVisible = true
      this.form.user_ID = row.user_ID
      this.userRowClick(row, column, event)
      // this.left = event.clientX
      // this.top = event.clientY
      // this.left = event.clientX - 200
      // this.top = event.y - 80
      // console.log('left' + this.left + '--top' + this.top)
      // this.contextMenuVisible = true
    },
    resetForm () {
      this.form.proposal = ''
      this.form.remark = ''
      this.addDialogVisible = false
    },
    async submitFrom () {
      await this.$http.post('insert/user/review', this.form)
      this.addDialogVisible = false
      this.getTableDataUser()
      this.getTableDataReview()
    }
  },
  data () {
    return {
      form: {
        remark: '',
        proposal: '',
        reviewer_ID: '',
        user_ID: ''
      },
      rightForm: {
        sales_ORG_ID: '',
        supportor_ORG_ID: ''
      },
      queryInfoUser: {
        query: '',
        business_STATUS: '',
        pagenum: 1,
        pagesize: 50,
        org_ID: this.$route.query.selected
      },
      queryInfoReview: {
        query: '',
        business_STATUS: '',
        pagenum: 1,
        pagesize: 10,
        org_ID: this.$route.query.selected
      },
      totleUser: 0,
      totleReview: 0,
      tableDataReview: [],
      tableDataUser: [],
      componentsId: '1',
      proposal: '',
      remark: '',
      addDialogVisible: false
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.contextmenu {
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
    margin: 0;
    padding: 0px 22px;

}
.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.contextmenu li button{
   color: #2c3e50;
}
.el-card__header{
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
