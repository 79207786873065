<template>
  <div>
    <div id="myChart" :style="{width: '1400px', height: '800px'}"></div>
  </div>
</template>
<script>
export default {
  name: 'AlldayCount',
  props: ['org_ID'],
  created () {
  },
  mounted () {
    this.drawLine()
  },
  methods: {
    handleNodeClick (data) {
      this.componentsId = data.id
    },
    async drawLine () {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myChart'))
      const { data: res } = await this.$http.get('query/start/accounts/online/byid', {
        params: this.form
      })
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis'// 显示线和数据
        },
        grid: {
          left: 30,
          // top: 30, // 距离容器上边界40像素
          bottom: 30// 距离容器下边界30像素
        },
        xAxis: {
          type: 'category',
          data: res.USER_NAME
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          label: {
            show: true,
            position: 'top'
          },
          type: 'bar',
          data: res.accounts
        }]
      })
    }
  },
  data () {
    return {
      form: {
        org_ID: this.org_ID
      }
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.contextmenu {
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
    margin: 0;
    padding: 0px 22px;

}
.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.contextmenu li button{
   color: #2c3e50;
}
</style>
