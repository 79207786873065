<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--侧边部门数据-->
      <el-col :xs="9" :sm="6" :md="4" :lg="4" :xl="4">
        <el-tree
          style="height:854px"
          :data="datas"
          :props="defaultProps"
          :expand-on-click-node="false"
          default-expand-all
          @node-click="handleNodeClick"
        />
      </el-col>
      <!--用户数据-->
      <el-col :xs="15" :sm="18" :md="20" :lg="20" :xl="20">
        <!--工具栏-->
          <div class="head-container">
            <basicInfo v-if="componentsId == 1" :org_ID="queryInfo.org_ID"></basicInfo>
            <platformUser v-if="componentsId == 2" :nodeUser="nodeId" :org_ID="queryInfo.org_ID"></platformUser>
            <Review v-if="componentsId == 3"></Review>
            <everydayCount v-if="componentsId == 5" :org_ID="queryInfo.org_ID"></everydayCount>
            <AlldayCount v-if="componentsId == 6" :org_ID="queryInfo.org_ID"></AlldayCount>
          </div>
        <!--表单渲染-->
        <!--表格渲染-->
        <!--分页组件-->
      </el-col>
    </el-row>
  </div>
</template>
<script>
import platformUser from '@/views/system/platform/user/index.vue'
import basicInfo from '@/views/system/platform/basic/index.vue'
import everydayCount from '@/views/system/platform/everydayCount/index.vue'
import Review from '@/views/system/platform/review/index.vue'
import AlldayCount from '@/views/system/platform/alldayCount/index.vue'
export default {
  name: 'platformInfo',
  components: {
    platformUser,
    basicInfo,
    everydayCount,
    Review,
    AlldayCount
  },
  created () {
  },
  methods: {
    handleNodeClick (data) {
      if (data.id !== 4) {
        this.componentsId = data.id
      }
    }
  },
  data () {
    return {
      nodeId: 2,
      form: {
        org_NAME: '',
        org_ADDRESS: '',
        org_CONTACT_NAME: '',
        org_CONTACT_PHONE: '',
        org_CONTACT_EMAIL: '',
        sale_COOPERATE: '',
        business_ACCOUNTS: '',
        business_END: '',
        sales_ID: ''
      },
      rightForm: {
        sales_ORG_ID: '',
        supportor_ORG_ID: ''
      },
      queryInfo: {
        query: '',
        business_STATUS: '',
        sales_ID: '',
        pagenum: 1,
        pagesize: 10,
        org_ID: this.$route.query.selected
      },
      totle: 0,
      tableData: [],
      componentsId: '1',
      datas: [{
        id: 1,
        label: '基本资料'
      }, {
        id: 2,
        label: '账户管理'
      }, {
        id: 3,
        label: '客户回访'
      }, {
        id: 4,
        label: '日志及分析',
        children: [{
          id: 5,
          label: '日活跃账户数统计'
        }, {
          id: 6,
          label: '账户活跃天数统计'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.contextmenu {
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
    margin: 0;
    padding: 0px 22px;

}
.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.contextmenu li button{
   color: #2c3e50;
}
</style>
